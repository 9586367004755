import React from "react"

import { Container, Row, Col } from "reactstrap"
import Link from "../components/link"
import Button from "../components/btn"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { FaGithub, FaBolt, FaHome, FaWrench } from "react-icons/fa"
import Form from "../components/form"
import Slider from "../components/slider"
import Box from "../components/box"
import Hr from "../components/hr"
import Benefits from "../components/benefits"
import styled from "styled-components"
import HomeFeatures from "../components/homeFeatures"
import Testimonials from '../components/testimonials'
import { StaticImage } from 'gatsby-plugin-image'
import PageTitle from "../components/page-title"

let StyledBackground = styled.div`
  background: linear-gradient(to bottom, #f9fbfd 0, #fff 100%);
`

let Service = ({ title, years, unit }) => (
  <Col xs={12} md={3} className="mb-3">
    <div class="fk-editor">
      <div style="text-align: center; font-size: 34px; line-height: 30px;">
        <font color="#1e50ae" style="font-size: 16px;">超过</font>
      </div>
      <div style="text-align: center; font-size: 34px; line-height: 30px;">
        <font color="#1e50ae"><b>{years}</b><span style="font-size: 16px;">{unit}</span></font>
      </div>
      <div style="text-align: center; font-size: 34px; line-height: 30px;">
        <font color="#1e50ae" style="font-size: 16px;">{title}</font></div>
    </div>
  </Col>
)

let Index = () => (
  <Layout>
    <SEO title="Home" />
    {/* <Slider /> */}

    <Container className="pt-4">
      <div className="text-center">
        <h4>为什么选择我们？</h4>
        <p className="text-muted">
          <b><span style="color: rgb(30, 80, 174);">——</span></b>
        </p>
      </div>
    </Container>
    <Container className="py-5">
      <Row>
        <Service title="专项经营" years="24" unit="年" />
        <Service title="国家专利" years="13" unit="项" />
        <Service title="专利产品" years="30" unit="款" />
        <Service title="优质客户" years="1000" unit="家" />
      </Row>
    </Container>
    <Container className="pt-4">
      <div className="text-center">
        <h4>产品展示</h4>
        <p className="text-muted">
          <b><span style="color: rgb(30, 80, 174);">——</span></b>
        </p>
      </div>
    </Container>
    <Container className="py-5">
      <Row>
        <Col xs={12} md={3} className="mb-3">
          <Link to="product/pfirst">
            <StaticImage src="../data/product/p1.webp" />
          </Link>
        </Col>
        <Col xs={12} md={3} className="mb-3">
          <div class="fk-editor">
            <div style="line-height: 30px;"><span style="font-size: 16px;">列车接近道口报警设备</span><br style="" /></div>
            <div style="line-height: 30px;"><span style="font-size: 16px; color: rgb(75, 75, 75);">——</span></div><div style="line-height: 30px;"><span style="font-size: 14px; color: rgb(75, 75, 75);">“道口综合控制台”是我公司研制的新一代专用于铁路道口的多功能防护设备，是集火车接近时的声光......</span></div>
            <div style="line-height: 30px;"><span style="color: rgb(73, 110, 232);"><br /></span></div>
          </div>
        </Col>
        <Col xs={12} md={3} className="mb-3">
          <Link to="product/p2">
            <StaticImage src="../data/product/p2.webp" />
          </Link>
        </Col>
        <Col xs={12} md={3} className="mb-3">
          <div class="fk-editor">
            <div style="line-height: 30px;"><span style="font-size: 16px;">铁路道口故障报警设备</span><br style="" /></div>
            <div style="line-height: 30px;"><span style="font-size: 16px; color: rgb(75, 75, 75);">——</span></div><div style="line-height: 30px;"><span style="font-size: 14px; color: rgb(75, 75, 75);">“故障报警器”（即道口反向报警器）是专用于有、无交流电源供电的铁路道口安全防护专利产品。</span></div>
            <div style="line-height: 30px;"><span style="color: rgb(73, 110, 232);"><br /></span></div>
          </div>
        </Col>

        <Col xs={12} md={3} className="mb-3">
          <div class="fk-editor">
            <div style="line-height: 30px;"><span style="font-size: 16px;">铁路道口视频监控设备</span><br style="" /></div>
            <div style="line-height: 30px;"><span style="font-size: 16px; color: rgb(75, 75, 75);">——</span></div><div style="line-height: 30px;"><span style="font-size: 14px; color: rgb(75, 75, 75);">“道口综合视频系统”是我公司研制的新一代专用于铁路道口的多功能防护设备，是集4G无线视频......</span></div>
            <div style="line-height: 30px;"><span style="color: rgb(73, 110, 232);"><br /></span></div>
          </div>
        </Col>
        <Col xs={12} md={3} className="mb-3">
          <Link to="product/p3">
            <StaticImage src="../data/product/p3.webp" />
          </Link>
        </Col>

        <Col xs={12} md={3} className="mb-3">
          <div class="fk-editor">
            <div style="line-height: 30px;"><span style="font-size: 16px;">铁路道口安防功能单元</span><br style="" /></div>
            <div style="line-height: 30px;"><span style="font-size: 16px; color: rgb(75, 75, 75);">——</span></div><div style="line-height: 30px;"><span style="font-size: 14px; color: rgb(75, 75, 75);">品字形信号灯是铁路专用的信号灯，灯板颜色为白色和红色，不同于两联或三联的公路信号灯。</span></div>
            <div style="line-height: 30px;"><span style="color: rgb(73, 110, 232);"><br /></span></div>
          </div>
        </Col>
        <Col xs={12} md={3} className="mb-3">
          <Link to="product/p4">
            <StaticImage src="../data/product/p4.webp" />
          </Link>
        </Col>
      </Row>
      <div style="text-align: center;">
        <Button to="/product">More+</Button>
      </div>
    </Container>

    <Container className="pt-4">
      <div className="text-center">
        <h4>关于公司</h4>
        <p className="text-muted">
          <b><span style="color: rgb(30, 80, 174);">——</span></b>
        </p>
      </div>
    </Container>
    <Container className="py-5">
      <PageTitle title="天津市长庆电子科技有限公司成立于1995年，主要从事智能电子产品的研究开发工作。集计算机软、硬件及智能电子产品开发、生产、销售及售后服务于一体，拥有多项国家专利。公司坚持服务社会，共同发展的理念，秉承“诚信、务实、敬业、创新”的企业精神，为客户提供了安全、高效、优质的售后服务。" >
      </PageTitle>
      <div style="text-align: center;">
        <Button to="/about">More+</Button>
      </div>
    </Container>

  </Layout>
)

export default Index
